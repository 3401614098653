import { InlineScript } from '@lcms/react-scripts';

export function MicrosoftClarity({ clarityId }: { clarityId?: string | null }) {
    if (!clarityId) {
        return null;
    }

    return (
        <InlineScript
            type="text/javascript"
            code={`(function(c,l,a,r,i,t,y){
c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
t=l.createElement(r);t.async=1;t.defer=1;t.src="https://www.clarity.ms/tag/"+i;
y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "${clarityId}");`}
        />
    )
}